<template>
  <div class="channel_card">
    <div class="channel_card__favorite_pin">
      <Icon
        v-if="channel.fav"
        icon="pins/pin_favorite_fill"
      />
    </div>
    <div
      v-if="channel.locked"
      class="channel_card__lock_pin"
    >
      <Icon
        icon="pins/pin_lock"
      />
    </div>
    <a
      :href="`/channel/${channel.id || channel.channel_id}/`"
      class="channel_card__icon flex"
      @click.prevent="navigateTo({ name: 'channel-arg', params: { arg: [channel.id || channel.channel_id] } })"
    >
      <img
        :src="channel.icon"
        :alt="channel.name"
        @error="putErrorImage"
      >
      <div class="channel_card__ch_name">{{ props.channel.name }}</div>
    </a>
    <div class="programProgressBack">
      <div
        class="programProgressFront"
        :style="{ width: getProgressPercent(channel) + '%' }"
      />
    </div>

    <div v-if="props.showEpg">
      <table>
        <tr
          v-for="program in epgList"
          :key="program[2]"
        >
          <td
            :class="{
              channel_card__title: isCurrentProgram(program),
              channel_card__program: !isCurrentProgram(program),
            }"
          >
            <div class="channel_card__programTime">
              {{ getTime(program[2]) }}
            </div>
          </td>
          <td
            :class="{
              channel_card__title: isCurrentProgram(program),
              channel_card__program: !isCurrentProgram(program),
            }"
          >
            {{ program[1] }}
          </td>
        </tr>
      </table>

      <button
        class="channel_card__btn_open_modal"
        @click="openModal"
      >
        Телепрограмма
      </button>
    </div>

    <div v-else>
      <div class="channel_card__title">
        {{ channel.program_name || labelNoProgram }}
      </div>
      <div v-if="channel.next_program_name" class="channel_card__program">
        {{ next }}: {{ channel.next_program_name }}
      </div>
    </div>
  </div>

  <epg-modal
    v-if="isModalVisible"
    :epg="epg"
    @close="isModalVisible = false"
  />
</template>

<script setup>
import { ref } from 'vue';
import { fetchChannelEpg } from '@/store/channelFetchers';
import { getDefaultImage } from '@/utils/ui/getDefaultImage';
import epgListMock from './data.json'; // todo: delete this mock data
import EpgModal from '@/components/ui/Epg/epgModal.vue';
import Icon from '@/components/common/icons/icon.vue';

const props = defineProps({
  channel: {
    type: Object,
    default: () => { return {}; },
  },
  showEpg: {
    type: Boolean,
    default: () => false,
  },
});

provide('channel', props.channel);

const next = 'Далее';
const labelNoProgram = 'Программа отсутствует';
const utcTimeCurrent = Date.UTC() / 1000;
const epgLimit = 6;
const epgList = setEpgList();
const epg = shallowRef([]);
const utcTime = ref(epgListMock.utc_time); // в будущем будем забирать значение из ответа API
const isModalVisible = ref(false); // for EPG modal

function getProgressPercent(channel) {
  const now = new Date().getTime();
  const width = Math.round(
    (100 * (now / 1000 - channel.program_begin_time)) / (channel.program_end_time - channel.program_begin_time),
  );
  return width > 100 ? 100 : width;
}

const getTime = programBeginTime =>
  new Date(programBeginTime * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

// todo: add timer for update data
const isCurrentProgram = program => program[2] <= utcTime.value && utcTime.value < program[3];

function putErrorImage(event) {
  event.target.src = getDefaultImage('poster_80_80');
}

function setEpgList() {
  const indexPrevProgram = epgListMock.programs.findIndex(p => p[3] > utcTimeCurrent) - 1;
  return epgListMock.programs.filter((value, i) => indexPrevProgram <= i && i < indexPrevProgram + epgLimit);
}

async function openModal() {
  epg.value = await fetchChannelEpg(props.channel.id);
  isModalVisible.value = true;
}
</script>
