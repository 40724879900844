<template>
  <VDialog
    :visible="true"
    size="large-plus"
    overlay-color="rgba(17, 20, 21, 0.6)"
    @close="$emit('close')"
  >
    <template #content>
      <div class="epg_dialog__content">
        <div class="epg_dialog__title">
          Телепрограмма
          <img :src="channel.icon" @error="putErrorImage">
          <button
            class="epg_dialog__cross"
            type="button"
            aria-label="Close"
            @click="$emit('close')"
          >
            <Icon icon="pins/pin_close" />
          </button>
        </div>
        <Lineup
          :epg="epg"
          :current-date="channel.program_begin_time * 1000"
        />
      </div>
    </template>
  </VDialog>
</template>

<script setup>
import VDialog from '@/components/common/VDialog.vue';
import Icon from '@/components/common/icons/icon.vue';
import Lineup from '@/components/ui/Epg/lineup.vue';
import { getDefaultImage } from '@/utils/ui/getDefaultImage';

const props = defineProps({
  epg: {
    type: Array,
    default: () => [],
  },
});
defineEmits(['close']);

const channel = inject('channel');

function putErrorImage(event) {
  event.target.src = getDefaultImage('poster_80_80');
}
</script>
